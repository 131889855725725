import React from 'react';
import SocialButtonRow from '../components/SocialButtonRow';
import Nav from '../components/Nav';

function Home() {
  return (
    <React.StrictMode>
          <SocialButtonRow />
          <div className="hero-title text-black xlarge-title title">
            Michał 
            <span className="hero-title__2row">Talaga</span>
          </div>
          <Nav />
    </React.StrictMode>
  );
}

export default Home;

 