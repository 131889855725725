import React from 'react';
import Badge from './Badge';
import Button from './Button';

function Project({ link, text, title, badges }) {
  return (
    <div className={`project`}>
      <div className="project-info">
      <div className="project-title title small-title">
          {title}
        </div>
        <div className="project-badges">
          {badges.map((badge, index) => (
            <Badge text={badge.text} hover_color={badge.hover_color} key={index} />
          ))}
        </div>
        <div className="project-desc  small-content">
          {text}
        </div>
      </div>
        
        <div className="project-content">
          
        <div className="project-button">
          <Button link={link} text="More" hover_color="yellow" />
        </div>
        </div>
        
    </div>

  );
}

export default Project;
