import { Swiper, SwiperSlide } from 'swiper/react';
import Project from "./Project";
import { Navigation } from 'swiper/modules';


import 'swiper/css';
import 'swiper/css/navigation';


function SimpleSlider() {
  const projects = [
        {
            link: 'https://github.com/MichalTalaga17/biblify',
            text: 'biblify is a simple organizer for biblical quotes, allowing users to browse, add, and manage quotes from the Bible.',
            title: 'biblify',
            badges: [
                { text: 'Swift', hover_color: 'blue' },
                { text: 'SwiftUI', hover_color: 'green' },
            ],
        },
        {
            link: 'https://github.com/MichalTalaga17/web-erasmus',
            text: 'website created as part of the Erasmus+ project by students of ZS im. W.Goetla in Sucha Beskidzka, promoting the Suski district and its surroundings, presenting the possibilities of the Bootstrap framework',
            title: 'Erasmus page',
            badges: [
                { text: 'Bootstrap', hover_color: 'red' },
            ],
        },
        {
            link: 'https://github.com/MichalTalaga17/js-weather-app',
            text: 'weather app using js, API and geolocal',
            title: 'weather app',
            badges: [
                { text: 'JS', hover_color: 'red' },
                { text: 'RESTAPI', hover_color: 'yellow' },
            ],
        },
        {
            link: 'https://github.com/MichalTalaga17/project-starter',
            text: 'this is a project starter template for web projects with Parcel bundler. It includes configurations and dependencies to help you kickstart your web development projects.',
            title: 'project starter',
            badges: [
                { text: 'PARCEL', hover_color: 'blue' },
                { text: 'REACT', hover_color: 'yellow' },
            ],
        }
    ];
  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={20}
      slidesPerView={1}
      navigation
      loop
      breakpoints={{
        200: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
        1400: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
      }}
    >
      {projects.map((project, index) => (
        <SwiperSlide><Project {...project} key={index} /></SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SimpleSlider;