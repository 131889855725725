import React from 'react';
import Social from '../components/Social';

function Contact() {
  return (
    <React.StrictMode>
        <div className="subpage-title text-black large-title title">
          Contact
        </div>
        <div className="contentspace row sm-col">
          <div className="formspace">
          <form className='contact-form column' action="https://formsubmit.co/michal.talaga.programming@gmail.com" method="POST">
              <label for="fname">Your Name</label>
              <input className='contact-input' type="text" id="fname" name="yourname" />
              <label for="email">Email</label>
              <input className='contact-input' type="email" id="email" name="email"  />
              <label for="subject">Subject</label>
              <input className='contact-input' type="" id="subject" name="subject" />
              <label for="message">Message</label>
              <textarea className='contact-input contact-message' id="message" name="message" />
              <input className='contact-submit button' type="submit" value="Submit" />
            </form>
          </div>
            
            <Social />
        </div>
    </React.StrictMode>
  );
}

export default Contact;

 