import React from 'react';
import ResumeSlider from '../components/ResumeSlider';

function Resume() {
  return (
    <React.StrictMode>
        <div className="subpage-title text-black large-title title">
          Resume
        </div>
        <div className="contentspace resume">
          <ResumeSlider />
          
        </div>
    </React.StrictMode>
  );
}

export default Resume;
