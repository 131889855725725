import React from 'react';


function Badge({text, hover_color}) {
  return (
    <div className={`badge small-content bg-${hover_color}`}>
      {text}
    </div>
  );
}

export default Badge;
