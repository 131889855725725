import React from "react";
import Home from "./pages/Index";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import Resume from "./pages/Resume";
 
function App() {
    return (
        <div className="column container gap-150">
            <div id="home">
                <Home />
            </div>
            <div id="project">
                <Projects />
            </div>
            <div id="resume">
                <Resume />
            </div>
            <div id="contact">
                <Contact />
            </div>
        </div>
    );
}
 
export default App;