import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import Experience from './Experience';
import Tools from './Tools';
import Languages from './Languages';


import 'swiper/css';
import 'swiper/css/navigation';


function ResumeSlider (){
  
  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={20}
      slidesPerView={3}
      breakpoints={{
        200: {
          slidesPerView: 1.2,
        },
        1200: {
          slidesPerView: 3,
        },
      }}
    >
        <SwiperSlide><Experience /></SwiperSlide>
        <SwiperSlide><Languages /></SwiperSlide>
        <SwiperSlide><Tools /></SwiperSlide>
    </Swiper>
  );
};
export default ResumeSlider;