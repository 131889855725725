import React from "react";

function SingleWork({ link, text, title }) {
    return (
        <a className={`work hover-shadow4`} href={link} target="blank">
            <div className="work-title content medium-content bold-badge">
                {title}
            </div>
            <div className="work-desc  content small-content">
                {text}
            </div>
        </a>

    );
}

export default SingleWork;
