import React from "react";
import SingleTechnology from "./SingleTechnology";

function Tools() {
    return (
        <div className="tools">
            <div className="resume-partname small-title title">
                Tools
            </div>
                <SingleTechnology text={"VS Code"} stars={4} />
                <SingleTechnology text={"GitHub"} stars={4} />
                <SingleTechnology text={"Figma"} stars={4} />
                <SingleTechnology text={"Canva"} stars={5} />
                <SingleTechnology text={"Spline"} stars={2} />
                <SingleTechnology text={"Office"} stars={4} />
                <SingleTechnology text={"MacOS"} stars={3} />
                <SingleTechnology text={"XCode"} stars={3} />
                <SingleTechnology text={"Jira"} stars={3} />
                <SingleTechnology text={"Git"} stars={4} />
        </div>
    );
            }
export default Tools;