import React from 'react';
import Button from './Button';

function Social() {
  return (
    <React.StrictMode>
      <div className="social column  gap-40" >
        <Button link="https://github.com/MichalTalaga17" text="Github" hover_color="yellow" target={"_blank"} add_class="button_bigger" />
        <Button link="https://twitter.com/michal_talaga" text="X" hover_color="pink" target={"_blank"} add_class="button_bigger"/>
        <Button link="https://www.linkedin.com/in/michał-talaga-a25054247/" text="Linkedin" hover_color="blue" target={"_blank"} add_class="button_bigger"/>
      </div>
    </React.StrictMode>
  );
}

export default Social;
