import React from 'react';
import SimpleSlider from '../components/SimpleSlider';

function Projects() {
  return (
    <React.StrictMode>
        <div className="subpage-title text-black large-title title">
          Projects
        </div>
        <div className="contentspace projects-swiper ">
          <SimpleSlider />
        </div>
    </React.StrictMode>
  );
}

export default Projects;

 