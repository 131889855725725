import React from "react";

function SingleTechnology({ text, stars }) {
    return (
        <div className={`single-technology hover-shadow4`}>
            
            <div className="technology-desc  middle-content content bold-badge">
                {text}
            </div>
            <div className="technology-starsspace">
                
                {Array.from({ length: stars }, (_, index) => (
                    <div key={index} className="star"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 23 21" fill="none">
                    <path d="M11.5 2.42705L13.3686 8.17807L13.537 8.6963H14.0819H20.1289L15.2368 12.2506L14.7959 12.5709L14.9643 13.0892L16.8329 18.8402L11.9408 15.2858L11.5 14.9656L11.0592 15.2858L6.16705 18.8402L8.03567 13.0892L8.20406 12.5709L7.76322 12.2506L2.87111 8.6963H8.91809H9.463L9.63138 8.17807L11.5 2.42705Z" fill="#FFF59F" stroke="black" stroke-width="1.5"/>
                    </svg></div>
                ))}
            </div>
        </div>

    );
}

export default SingleTechnology;
