import React from 'react';


function Button({link, text, hover_color, add_class, target}) {
  return (
    <a href={link} className={`button hover-${hover_color} ${add_class}`} target={target}>
        {text}
    </a>
  );
}

export default Button;
