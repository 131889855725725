import React from 'react';
import Button from './Button';

function Nav() {
  return (
    <React.StrictMode>
      <div className="nav row gap-150 sm-col">
        <Button link="#projects" text="Projects" hover_color="yellow" add_class="button_bigger" />
        <Button link="#resume" text="Resume" hover_color="pink" add_class="button_bigger"/>
        <Button link="#contact" text="Contact" hover_color="blue" add_class="button_bigger"/>
      </div>
    </React.StrictMode>
  );
}

export default Nav;
